import * as React from 'react';

import { cn } from '@/lib/utils';
import { Label } from './label';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<
  HTMLInputElement,
  InputProps & {
    label?: string;
    vertical?: boolean;
  }
>(({ className, type, ...props }, ref) => {
  const input = (
    <input
      type={type}
      className={cn(
        'border flex h-7 w-full rounded-md bg-background px-3 py-2 text-sm  file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none   disabled:cursor-not-allowed disabled:opacity-50 my-1',
        className
      )}
      ref={ref}
      {...props}
    />
  );

  if (props.label) {
    return (
      <div
        className={
          props.vertical
            ? 'flex flex-col mt-2 mb-1'
            : 'space-x-2 flex flex-row items-center'
        }
      >
        <Label>{props.label}</Label>
        {input}
      </div>
    );
  }
  return input;
});
Input.displayName = 'Input';

export { Input };
